import axios from "axios";
import HelperService from "../services/helper-service";
import { KuikaAppManager } from "../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../shared/utilty/main-functions";
import LocalStorageService from "./local-storage-service";

declare const window: any;

const backendUrl = KuikaAppManager.GetBackendUrl();
let isTokenRefreshing = false;
let failedQueue = [] as any;
// LocalstorageService
const localStorageService = LocalStorageService.getService();

const processQueue = (error, token = null) => {
  failedQueue.forEach((promise) => {
    if (error) {
      promise.reject(error);
    } else {
      promise.resolve(token);
    }
  });
  failedQueue = [];
};

// Add a request interceptor
axios.interceptors.request.use(
  (config) => {
    const urlItems = document.location.pathname.substring(1).split(/\//);
    const token = localStorageService.getAccessToken();
    const language = localStorage.getItem("ml");
    if (language && language.length) {
      config.headers.cultureName = language;
    }
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
      config.headers.domainName = urlItems[0];
    }
    if (window.kuika && window.kuika?.project && window.kuika?.project.domainId) {
      config.headers.domainId = window.kuika?.project.domainId;
    }
    // config.headers['Content-Type'] = 'application/json';
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

// Add a response interceptor
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const originalRequest = error.config;

    if (!error.response) {
      return Promise.reject(error);
    }

    if (error.response.status === 401 && originalRequest.url === `${backendUrl}/auth/login`) {
      return Promise.reject(error);
    }

    if (error.response.status === 401 && !originalRequest._retry) {
      // Starting to try refresh token
      originalRequest._retry = true;
      if (isTokenRefreshing === false) {
        // Token refreshing.
        isTokenRefreshing = true;
        const refreshToken = localStorageService.getRefreshToken();
        if (refreshToken === "null" || refreshToken === "" || refreshToken === undefined || refreshToken === null) {
          localStorageService.clearToken();
          const language = localStorage.getItem("ml");
          let translatedTitle = "";
          switch (language) {
            case "fr_FR":
              translatedTitle = "Pour ce faire, vous devez vous connecter au système";
              break;
            case "tr_TR":
              translatedTitle = "Bu işlemi yapabilmeniz için sisteme giriş yapmanız gerkmektedir";
              break;
            case "en_US":
              translatedTitle = "To do this, you need to sign in to the system";
              break;
            default:
              translatedTitle = "To do this, you need to sign in to the system";
              break;
          }
          KMainFunctions.displayWarningNotification(translatedTitle);
          // HelperService.gotoLogin(); buraya geldiğinde zaten logine redirect etmiş olduğu için kapatıldı farklı case'ler varsa gözden geçirilmeli
          return;
        }
        return axios
          .post(`${backendUrl}/auth/refreshtoken`, {
            refreshtoken: refreshToken
          })
          .then((res) => {
            // Token refreshed.
            isTokenRefreshing = false;
            if (res && res.status === 200) {
              localStorageService.setToken(res.data);
              axios.defaults.headers.common.Authorization = `Bearer ${localStorageService.getAccessToken()}`;
              processQueue(null, res.data.token);
              return axios(originalRequest);
            }
          })
          .catch((e) => {
            localStorageService.clearToken();
            HelperService.gotoLogin();
          });
      }
      // Token already refreshing, requests adding to queue.
      return new Promise((resolve, reject) => {
        failedQueue.push({ resolve, reject });
      })
        .then((token) => {
          originalRequest.headers.Authorization = `Bearer ${token}`;
          return axios(originalRequest);
        })
        .catch((err) => {
          return Promise.reject(err);
        });
    }

    if (error.response.status >= 400 && error.config.url.indexOf("refreshtoken") > -1) {
      localStorageService.clearToken();
      HelperService.gotoLogin();
    }
    return Promise.reject(error);
  }
);
