import CloseOutlined from "@ant-design/icons/lib/icons/CloseOutlined";
import { Modal } from "antd";
import { PureComponent, ReactNode } from "react";
import { isMobile } from "react-device-detect";
import { Camera, FACING_MODES, IMAGE_TYPES } from "react-html5-camera-photo";
import { connect } from "react-redux";
import { Redirect, Route } from "react-router-dom";
import LocalStorageService from "../auth/local-storage-service";
import { PaymentElementStripe } from "../components/web-components";
import { IIyzicoCheckoutFormResponseDto } from "../models/dto/iyzico.dto";
import {
  hideIyzicoDrawer,
  hidePhotoTake,
  hideStripeDrawer,
  showIyzicoDrawer,
  showPhotoTake,
  showStripeDrawer
} from "../redux/photo-take/actions";
import { AppState } from "../redux/root-reducer";
import { UserService } from "../services/user-service";
import { KContext } from "../shared/hoc/with-context";
import { KNavigator, withHistory } from "../shared/hoc/with-history";
import { KMainFunctions } from "../shared/utilty/main-functions";

declare let window: Window & { userInfo: any };

interface PrivateRouteProps {
  component: any;
  exact?: boolean;
  path: string;
  photoTakeVisible?: any;
  hidePhotoTake?: any;
  stripeDrawerVisible?: any;
  iyzicoDrawerVisible?: any;
  hideStripeDrawer: any;
  hideIyzicoDrawer: any;
  stripeData?: any;
  stripeCallBack?: any;
  iyzicoCallBack?: any;
  iyzicoData?: any;
  screen?: any;
  futureUse?: any;
  navigator?: KNavigator;
}

interface PrivateRouteState {
  context: KContext | undefined;
}

class PrivateRoute extends PureComponent<PrivateRouteProps, PrivateRouteState> {
  constructor(props: PrivateRouteProps) {
    super(props);
    this.state = { context: undefined };
  }

  async componentDidMount() {
    if (!(this.props.path === "/redirect" && this.props.screen === undefined)) {
      await UserService.GetUserInfo();
    }

    this.setState({ context: new KContext() });

    const virtualDirectoryURL: string =
      KMainFunctions.virtualDirectoryURL === "auto" ? "" : KMainFunctions.virtualDirectoryURL;
    const pathname: string = window.location.pathname.replace(virtualDirectoryURL, "");

    if (pathname === "/" && window.userInfo) {
      this.props.navigator?.gotoStartingScreen();
    }
  }

  handleClosePhoto = () => {
    const imgNode: HTMLImageElement = document
      .getElementsByClassName("react-html5-camera-photo")[0]
      .getElementsByTagName("img")[0];
    imgNode.src = "";
    this.props.hidePhotoTake();
  };

  renderStripeDrawer = () => {
    if (this.props.stripeDrawerVisible) {
      return (
        <Modal
          destroyOnClose
          onCancel={() => {
            this.props.screen.setState(
              { OpenStripePaymentDrawer: { isSuccess: false, errorMessage: "Action aborted by the user" } },
              () => {
                this.props.hideStripeDrawer();
                this.props.stripeCallBack();
              }
            );
          }}
          footer={null}
          open={this.props.stripeDrawerVisible}
        >
          <PaymentElementStripe
            stripeAction={this.props.stripeData}
            callback={this.props.stripeCallBack}
            screen={this.props.screen}
            futureUse={this.props.futureUse}
          />
        </Modal>
      );
    }
    return <></>;
  };

  renderIyzicoDrawer = () => {
    if (this.props.iyzicoDrawerVisible) {
      var timer;
      setTimeout(() => {
        const data = this.props.iyzicoData.data as IIyzicoCheckoutFormResponseDto;
        var checkoutFormContent = data.checkoutFormContent;
        var checkoutFormContent = checkoutFormContent.replace(
          /<script\s+type\s*=\s*"text\/javascript"\s*>|<\/script>/gi,
          ""
        );
        const iframe = document.getElementById("iyzipay-iframe") as any;
        const iframeWin = iframe.contentWindow || iframe;
        const iframeDoc = iframe.contentDocument || iframeWin.document;
        var scriptElement = iframeDoc.createElement("script");
        scriptElement.innerHTML = checkoutFormContent;
        iframeDoc.documentElement.addEventListener("popstate", () => alert("hi"));

        timer = setInterval(() => {
          const iframe = document.getElementById("iyzipay-iframe") as any;
          const iframeWin = iframe.contentWindow || iframe;
          try {
            var a = iframeWin.location.host;
            if (a === "") {
            } else {
              clearInterval(timer);
              this.props.screen.setState(
                {
                  IyzicoInitializeCheckoutForm: {
                    checkoutFormContent: data.checkoutFormContent,
                    token: data.token,
                    status: data.status,
                    statusCode: data.statusCode,
                    errorMessage: data.errorMessage,
                    conversationId: data.conversationId
                  }
                },
                () => {
                  this.props.hideIyzicoDrawer();
                  if (this.props.iyzicoCallBack) {
                    this.props.iyzicoCallBack();
                  }
                }
              );
            }
          } catch (error) {
            clearInterval(timer);
            this.props.screen.setState(
              {
                IyzicoInitializeCheckoutForm: {
                  checkoutFormContent: data.checkoutFormContent,
                  token: data.token,
                  status: data.status,
                  statusCode: data.statusCode,
                  errorMessage: data.errorMessage,
                  conversationId: data.conversationId
                }
              },
              () => {
                this.props.hideIyzicoDrawer();
                if (this.props.iyzicoCallBack) {
                  this.props.iyzicoCallBack();
                }
              }
            );
          }
        }, 50);
        iframeDoc.documentElement.appendChild(scriptElement);
      }, 1000);
      return (
        <Modal
          keyboard
          width={"100%"}
          destroyOnClose
          onCancel={() => {
            this.props.screen.setState(
              {
                IyzicoInitializeCheckoutForm: {
                  checkoutFormContent: "",
                  token: "",
                  status: "Aborted",
                  statusCode: 0,
                  errorMessage: "Action aborted by the user",
                  conversationId: ""
                }
              },
              () => {
                this.props.hideIyzicoDrawer();
                clearInterval(timer);
                if (this.props.iyzicoCallBack) {
                  this.props.iyzicoCallBack();
                }
              }
            );
          }}
          footer={null}
          open={this.props.iyzicoDrawerVisible}
        >
          <iframe
            height="800"
            width="100%"
            id="iyzipay-iframe"
            srcDoc="<div id='iyzipay-checkout-form' className='responsive'></div>"
          ></iframe>
        </Modal>
      );
    }
    return <></>;
  };

  renderPhotoTake = () => {
    if (!this.props.photoTakeVisible) {
      return <></>;
    }
    if (isMobile) {
      return (
        <>
          <CloseOutlined
            onClick={this.handleClosePhoto}
            style={{ position: "absolute", top: 60, fontSize: 40, zIndex: 99999, left: `calc(90vw)` }}
          />
          <Camera
            imageType={IMAGE_TYPES.JPG}
            isFullscreen
            isImageMirror={false}
            idealFacingMode={FACING_MODES.ENVIRONMENT}
          />
        </>
      );
    }
    return (
      <Modal
        destroyOnClose
        closable
        onCancel={this.handleClosePhoto}
        width="fit-content"
        footer={null}
        open={this.props.photoTakeVisible}
      >
        <Camera imageType={IMAGE_TYPES.JPG} isImageMirror={false} idealFacingMode={FACING_MODES.ENVIRONMENT} />
      </Modal>
    );
  };

  render(): ReactNode {
    if (this.state.context !== undefined) {
      return (
        <>
          {this.renderPhotoTake()}
          {this.renderStripeDrawer()}
          {this.renderIyzicoDrawer()}
          <Route
            exact={this.props.exact}
            path={this.props.path}
            render={(props) => {
              return LocalStorageService.getAccessToken() !== null ? (
                <this.props.component {...props} context={this.state.context} />
              ) : (
                <Redirect
                  to={{
                    pathname: "/signin",
                    state: { from: props.location }
                  }}
                />
              );
            }}
          />
        </>
      );
    }
    return <div>Loading!</div>;
  }
}

const PrivateRoute2 = ({ component: Component, ...rest }) => {
  return (
    <>
      <Route
        {...rest}
        render={(props) =>
          LocalStorageService.getAccessToken() !== null ? (
            <Component {...props} />
          ) : (
            <Redirect
              to={{
                pathname: "/signin",
                state: { from: props.location }
              }}
            />
          )
        }
      />
    </>
  );
};

const mapStateToProps = (state: AppState) => {
  return {
    spinnerCount: state.spinnerReducer.spinnerCount,
    photoTakeVisible: state.photoTakeReducer.visible,
    stripeDrawerVisible: state.stripeDrawerReducer.visible,
    stripeData: state.stripeDrawerReducer.payload,
    stripeCallBack: state.stripeDrawerReducer.callback,
    iyzicoCallBack: state.iyzicoDrawerReducer.callback,
    screen: state.stripeDrawerReducer.screen ?? state.iyzicoDrawerReducer.screen,
    futureUse: state.stripeDrawerReducer.futureUse,
    iyzicoDrawerVisible: state.iyzicoDrawerReducer.visible,
    iyzicoData: state.iyzicoDrawerReducer.payload
  };
};

const mapDispatchToProps = (dispatch) => ({
  showPhotoTake: () => dispatch(showPhotoTake()),
  hidePhotoTake: () => dispatch(hidePhotoTake()),
  showStripeDrawer: (data, callback, screen, futureUse) =>
    dispatch(showStripeDrawer(data, callback, screen, futureUse)),
  hideStripeDrawer: (data, callback, screen) => dispatch(hideStripeDrawer(data, callback, screen)),
  showIyzicoDrawer: (data, callback, that) => dispatch(showIyzicoDrawer(data, callback, that)),
  hideIyzicoDrawer: (data, callback, that) => dispatch(hideIyzicoDrawer(data, callback, that))
});

const privateRoute = connect(mapStateToProps, mapDispatchToProps)(withHistory(PrivateRoute));
export { privateRoute as PrivateRoute };
